import React from 'react'
import Grid from '@material-ui/core/Grid'
import Cal from '@material-ui/icons/Today'
import Image from './Image'

export default function News({news}) {
  return (
    <Grid item xs={12} md={8}>
      <a href={"/actualites"+news.node.fields.slug}>
        <Grid container>
          <Grid item xs={2}><Cal/></Grid>
          <Grid item xs={10}><time>{news.node.frontmatter.date}</time></Grid>
        </Grid>
        <h3>{news.node.frontmatter.title}</h3>
        <Image src={news.node.frontmatter.thumbnail} alt={news.node.frontmatter.title} title={news.node.frontmatter.title}/>
      </a>
    </Grid>
  );
}