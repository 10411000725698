import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import Grid from '@material-ui/core/Grid'
import News from '../beans/News'

export default function NewsList({data, limit}) {
  return (
    <StaticQuery 
      query={graphql`
        query News {
          allMarkdownRemark(filter: {frontmatter: {layout: {eq: "news"}}}, sort: {order: DESC, fields: frontmatter___date}) {
            edges {
              node {
                frontmatter {
                  thumbnail
                  title
                  date(formatString: "dddd D MMMM YYYY", locale:"fr")
                }
                excerpt
                fields {
                  slug
                }
              }
            }
            pageInfo {
              currentPage
              hasNextPage
              pageCount
              itemCount
              totalCount
              perPage
              hasPreviousPage
            }
          }
        }
      `}
    render={data => (
      <Grid container>
        {data.allMarkdownRemark.edges.slice(0, limit).map((news, id) => (
          <Grid className="card" key={id} item xs={12} md={4}>
            <News news={news}/>
          </Grid>
        ))}
      </Grid>
    )}
    />
  )
}
