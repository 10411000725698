import React from 'react'
import Grid from '@material-ui/core/Grid'
import Image from './Image'

export default function Partner({item}) {
  return (
    <Grid item xs={12} md={8}>
      <Image src={item.node.frontmatter.logo} alt={item.node.frontmatter.title} title={item.node.frontmatter.title}/>
    </Grid>
  );
}