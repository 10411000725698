import React from "react"
import Layout from '../components/template/Layout'
import ActionsList from '../components/lists/ActionsList'
import NewsList from '../components/lists/NewsList'
import PartnersList from '../components/lists/PartnersList'
import { Typography, Container, Button } from '@material-ui/core'

export default function Home() {
  return (
    <Layout title="Jeune Chambre Economique de Châteaubriant et sa région" description="Incubateur de leaders citoyens" type="Accueil" image="https://chateaubriant.jcef.asso.fr/static/b6563a053b3907c2c82b4c56278a3648/2a4de/logo.png">
      <Container>
        <Typography variant="h2" gutterBottom>Nos dernières actualités</Typography>
        <NewsList limit="3"/>
        <br/>
        <Button href="/actualites/" title="Nos actualités">&gt; Toutes nos actualités</Button>
        <br/><br/><hr/>
        <Typography variant="h2" gutterBottom>Nos dernières actions</Typography>
        <ActionsList limit="3" onlyCards="true"/>
        <br/>
        <Button href="/actions/" title="Toutes nos actions">&gt; Toutes nos actions</Button>
        <br/><br/><hr/>
        <Typography variant="h2" gutterBottom>Nos partenaires</Typography>
        <PartnersList/>
        <br/><br/><hr/>
        <Typography variant="h2" gutterBottom>Notre association</Typography>
        La Jeune Chambre Économique de Châteaubriant et sa Région, fondée en 1963, est indépendante de tout courant politique, religieux ou syndical. Sa principale vocation est de contribuer au bien-être de son territoire et de ses individus par la mise en place de projets d'ordre social, économique, culturel et communautaire.
        <br/><br/>
        <ul>
          <li>
            <a href="/association/" title="En savoir plus sur notre association">En savoir plus sur notre association</a>
          </li>
          <li>
            <a href="/membres/" title="Voir les membres de notre association">Nos Membres</a>
          </li>
          <li>
            <a href="/presidents/" title="Voir les présidents de notre association">Nos Présidents</a>
          </li>
          <li>
            <a href="/ambassadeurs/" title="Voir les ambassadeurs de notre association">Nos Ambassadeurs</a>
          </li>
          <li>
            <a href="/senateurs/" title="Voir les présidents de notre association">Nos Sénateurs</a>
          </li>
        </ul>
        <Button href="/newsletters/" title="Nos newsletters">&gt; Toutes nos newsletters</Button>
        <br/><br/>
      </Container>
    </Layout>
  );
}

