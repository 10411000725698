import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import Grid from '@material-ui/core/Grid'
import Partner from '../beans/Partner'

export default function Partners({data}) {
  return (
    <StaticQuery 
      query={graphql`
      query Partners {
        allMarkdownRemark(filter: {frontmatter: {layout: {eq: "partner"}}}) {
          edges {
            node {
              frontmatter {
                logo
                title
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Grid container>
        {data.allMarkdownRemark.edges.map((item, id) => (
          <Grid key={id} item xs={6} md={4}>
            <Partner item={item}/>
          </Grid>
        ))}
      </Grid>
      )}
    />
  )
}